<template>
  <a 
    :href="`?load-page=${page}`"
    @click.prevent="loadMore"
  >
    <slot>
      <base-button
        class="-primary"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <base-loader v-if="loading" :loading="true" />
        <span v-else>{{ text }}</span>
      </base-button>
    </slot>
  </a>
</template>

<script>
import BaseLoader from '@/components/atoms/BaseLoader.vue'
import BaseButton from '@/components/atoms/BaseButton.vue'
export default {
  name: 'ButtonWithLoader',
  components: {
    BaseButton,
    BaseLoader
  },
  props: {
    /**
     * Shows the loader on top of the existing content
     */
    loading: {
      default: true,
      type: Boolean,
    },
    text: {
      default: '',
      type: String,
    },
    page: {
      default: 2,
      type: Number,
    },
  },
  methods: {
    loadMore () {
      this.$emit('load-more')
    }
  }
}
</script>
